import React, { FC } from 'react';
import { Col, Row, Tag, Typography } from 'antd';
import trafficLightThresholdColors from '../../config/trafficLightThresholdColors';
import { ICasesTooltipSideffectsListThresholdLamp } from '../../models/components/CasesTooltipSideffectsListThresholdLamp';

const { Text } = Typography;

const CasesTooltipSideffectsListThresholdLamp: FC<ICasesTooltipSideffectsListThresholdLamp> = (
  sideEffects: any = []
): JSX.Element => {
  return (
    <Row style={{ minWidth: 280 }}>
      <Col span={24}>
        {sideEffects.map((item: any, index: number) => (
          <Row key={index}>
            <Col span={3}>
              <Tag
                color={trafficLightThresholdColors(
                  item,
                  item.values.length > 0 ? Math.max(...item?.values) : 'empty'
                )}
                className="side-effect-state rounded-color-state"
              ></Tag>
            </Col>
            <Col span={21}>
              <Text className="item-name">{item.name}</Text>
            </Col>
          </Row>
        ))}
      </Col>
    </Row>
  );
};

export default CasesTooltipSideffectsListThresholdLamp;
