import React, { FC } from 'react';
import { Typography, Row, Col, Divider } from 'antd';
import { NavLink } from 'react-router-dom';
import { IAbout } from '../../models/pages/IAbout';
import ceLogo from './../../../assets/about/CE-Piktogramm.svg';
import handbookLogo from './../../../assets/about/Handbuch-Piktogramm.svg';
import snLogo from './../../../assets/about/SN-Piktogramm.svg';
import manufacturerLogo from './../../../assets/about/Hersteller-Piktogramm.svg';
import './../../scss/pages/About.scss';

const { Title, Text } = Typography;

const About: FC<IAbout> = (): JSX.Element => (
  <div
    className="dtb-content-inner-scroll-container"
    style={{
      paddingTop: 14,
      paddingBottom: 14,
      marginLeft: 14,
      marginRight: 14,
      overflowY: 'auto',
      display: 'flex',
      minHeight: '100%'
    }}
  >
    <Row justify="center" style={{ background: 'none' }}>
      <Col xs={24} sm={24} xl={24} className="about-wrapper">
        <Title level={2} className="text-center ">
          Über Tino DTB
        </Title>

        <Col className="content-wrapper dtb-long-text">
          <Text className="display-block margin-bottom-m">
            Der Tino DTB ist ein digitales Medizinprodukt zur aktiven
            Unterstützung des Therapiemanagements von Patienten, die in einem
            onkologischen Versorgungsprozess medikamentös therapiert und durch
            Ärzte und medizinisches Fachpersonal begleitet werden. Er hilft
            Patienten, sich in ihrer Therapie besser zurechtzufinden, den
            Überblick zu behalten und das medizinische Betreuungsteam in
            Echtzeit über das Befinden zu informieren. Der Tino DTB ist ein in
            Deutschland entwickeltes und registriertes Medizinprodukt der Klasse
            I. Er wird unter Einbezug von niedergelassenen Hämatologen /
            Onkologen, Gynäkologen, Klinikambulanzärzten sowie onkologischen
            Pharmazeuten ständig weiterentwickelt und an die Bedürfnisse der
            Versorgung angepasst. Tino DTB steht dabei für "Therapie IN der
            Onkologie. Digitaler Therapie-Begleiter“ mit dem Ziel, die Patienten
            und das medizinische Betreuungsteam in bestmöglicher Weise bei der
            Krebstherapie zu unterstützen.
          </Text>
          <br /> <br />
          <Text>
            Die Tino DTB Webanwendung bietet Ärzten und medizinischem
            Fachpersonal folgende unterstützende Funktionen:
          </Text>
          <ol className="margin-left-m" style={{ padding: 0, paddingLeft: 15 }}>
            <li style={{ fontWeight: 'bold', paddingTop: 15 }}>
              <span style={{ fontWeight: 'bold' }}>Therapieplan:</span>
              <br />
              <Text style={{ fontWeight: 'normal' }}>
                Der behandelnde Arzt importiert Therapiepläne für den jeweiligen
                Patienten in die Anwendung. Diese werden sowohl dem
                medizinischem Betreuungsteam in der Webanwendung als auch dem
                Patienten in der App einnahmezeitpunkt- und medikationsbezogen
                angezeigt und können ggf. dem Patienten aufgeschlüsselt
                graphisch zur Verfügung gestellt werden. Des Weiteren können
                zeitpunktgenaue Erinnerungsfunktionen zur Einnahme eingestellt
                werden.
              </Text>
            </li>
            <li style={{ fontWeight: 'bold', padding: 0, paddingTop: 15 }}>
              <span style={{ fontWeight: 'bold' }}>Monitoring:</span>
              <br />
              <Text style={{ fontWeight: 'normal' }}>
                Der Tino DTB bietet dem Patienten die Möglichkeit der
                Dokumentation verschiedener Daten, welche in Echtzeit mit dem
                medizinischen Betreuungsteam geteilt werden. Damit hat das Team
                immer einen Überblick über den aktuellen Gesundheitszustand,
                kann die Therapie besser einstellen und kritischen Verläufen
                vorbeugen.
              </Text>
              <ol
                style={{
                  fontWeight: 'bold',
                  margin: 0,
                  padding: 0,
                  paddingLeft: 15
                }}
                type="a"
                className="margin-left-l"
              >
                <li>
                  <span style={{ fontWeight: 'bold' }}>
                    Medikationseinnahmen
                  </span>
                  <br />
                  <Text style={{ fontWeight: 'normal' }}>
                    Das medizinische Fachpersonal bekommt über die Anwendung
                    Informationen, ob Patienten ihre Medikation nach den
                    Vorgaben ihres Therapieplans eingenommen oder unter Angabe
                    von Gründen nicht eingenommen haben.
                  </Text>
                </li>
                <li>
                  <span style={{ fontWeight: 'bold' }}>Gesundheitszustand</span>
                  <br />
                  <Text style={{ fontWeight: 'normal' }}>
                    Das medizinische Fachpersonal kann über die Anwendung
                    täglich Informationen über den aktuellen Gesundheitszustand
                    ihrer betreuten Patienten erhalten. Patienten- und
                    therapieindividuell können Nebenwirkungen und Vitalwerte
                    festgelegt werden, welche täglich vom Patienten dokumentiert
                    werden können. Für diese Werte können individuelle
                    Toleranzbereiche konfiguriert werden. Werden durch den
                    Patienten Werte dokumentiert, welche außerhalb des
                    festgelegten Bereiches liegen, wird dieses dem medizinischen
                    Betreuungsteam durch automatisch generierte optische
                    Meldungen angezeigt.
                  </Text>
                </li>
              </ol>
            </li>
          </ol>
        </Col>
        <Divider />
        <Row justify="space-between" className="wrapper-image-list">
          <Col span={4} className="margin-bottom-m">
            <img alt="CE Piktogramm" className="img-item" src={ceLogo} />
          </Col>
          <Col span={4} className="margin-bottom-m">
            <img
              alt="Handbuch Piktogramm"
              className="img-item"
              src={handbookLogo}
            />
          </Col>
          <Col span={6} className="margin-bottom-m">
            <Row>
              <Col flex="100px">
                <img alt="SN-Piktogramm" className="img-item" src={snLogo} />
              </Col>
              <Col flex="auto">
                <Text className="display-block">
                  Ver.: {process.env.REACT_APP_VERSION}
                  <br />
                  Build: 2.3
                  <br />
                  Date: {process.env.REACT_APP_RELEASEDATE}
                </Text>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={8} xl={8} className="margin-bottom-m">
            <Row>
              <Col flex="50px">
                <img
                  alt="Hersteller-Piktogramm"
                  className="img-item"
                  src={manufacturerLogo}
                />
              </Col>
              <Col flex="auto" style={{ width: 80, overflow: 'hidden' }}>
                <Text className="display-block">
                  DTB Gesellschaft für digitale Therapiebegleitung mbH
                  <br />
                  Otto-Schott-Str. 15
                  <br />
                  07745 Jena
                </Text>
                <br />
                <a
                  style={{
                    marginTop: 5,
                    display: 'inline-block',
                    width: '100%'
                    // color: '#753055'
                  }}
                  className="display-block "
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.digitale-therapiebegleitung.de"
                >
                  www.digitale-therapiebegleitung.de
                </a>
                <Text
                  style={{
                    marginTop: 5,
                    display: 'inline-block'
                  }}
                  className="margin-right-s"
                >
                  E-Mail:{' '}
                  <a
                    href="mailto:info@digitale-therapiebegleitung.de"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    info@digitale-therapiebegleitung.de
                  </a>
                </Text>

                <br />
                <Text
                  style={{ marginTop: 5, display: 'inline-block' }}
                  className="display-block margin-top-s"
                >
                  Amtsgericht Jena, HRB 518202
                </Text>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  </div>
);

export default About;
